import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable()

export class GlobalVariables {
    
    public apiUrl = 'https://astrologersdesk.net/api/v1';
    public rootUrl = 'https://astrologersdesk.net/';
    public homeUrl = 'https://celebrity.saptarishis.app/';
    //public homeUrl = environment.homeUrl;

    


}
