import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/_services/api.service';
import { GlobalVariables } from 'src/app/common/global-variables';
import { DatePipe } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { HostListener } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-celebrity-lists',
  templateUrl: './celebrity-lists.component.html',
  styleUrls: ['./celebrity-lists.component.css']
})
export class CelebrityListsComponent implements OnInit {

  celebrityLists: [];
  categoryLists: [];
  alphabet: string;
  name: string;
  catId: Number;
  form: FormGroup;
  action = 'inactive';
  showLoadMore = 0;
  pageNo = 1;
  lastArrCelebrities: any = [];
  loading = false;
  checklen = 0;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    public globalVar: GlobalVariables,
    public datePipe: DatePipe,
  ) { }

  ngOnInit(): void {

    //query data get
    this.route.queryParams.subscribe(params => {
      this.alphabet = (params['alphabet']) ? params['alphabet'] : '';
      this.name = (params['name']) ? params['name'] : '';
      this.catId = (params['cat_id']) ? params['cat_id'] : '';
    });

    //cetegorty lists
    this.getCategoryLists();
    // celebrity lists
    this.getcelebrityLists(this.pageNo);
  }

  getcelebrityLists(page) {
    //Show Loading
    this.loading = true;

    this.apiService.getCelebrityListsApi(this.alphabet, this.name, this.catId, page)
      .then(responseData => {
        //Remove loading
        this.loading = false;
        if ((responseData as any).response == 'success') {

          this.celebrityLists = (responseData as any).data;
          this.lastArrCelebrities = this.lastArrCelebrities.concat(this.celebrityLists);
          this.checklen = 1;
          let pagination_result = (responseData as any).pagination_result;

          if (pagination_result.pagination > page) {
            //Show Load More
            this.showLoadMore = 1;
            //Action Inactive
            this.action = "inactive";
          } else {
            //Hide Load More
            this.showLoadMore = 0;
            //Action Inactive
            this.action = "active";
          }
        } else {
          this.checklen = 2;
        }
      }).catch(e => {
        // hide the loading popup
        this.loading = false;
        console.log('error - ' + e.message);
      });
  }

  getCategoryLists() {
    this.apiService.getCategoryListsApi()
      .then(responseData => {
        if ((responseData as any).response == 'success') {
          this.categoryLists = (responseData as any).data;
        }
      }).catch(e => {
        console.log('error - ' + e.message);
      });

  }

  @HostListener("window:scroll", []) onWindowScroll() {

    if ($(window).scrollTop() + $(window).height() > $(".page-section").height() && this.action == 'inactive') {
      this.action = 'active';
      //Increment page no
      this.pageNo = this.pageNo + 1;
      //Call Get Celebrity Method
      this.getcelebrityLists(this.pageNo);
    }
  }

}
